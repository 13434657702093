<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/contacts/${uuid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-card>
      <h4>
          {{$t("Labels.ContactInformation")}}
      </h4>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="configValidation">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Name')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input id="name" v-model="contact.name" :state="errors.length > 0 ? false:null" name="name" :placeholder="$t('Labels.Name')" maxlength="254" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Phone')"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required|digits:10"
            >
              <cleave class="form-control" id="phone" v-model="contact.phone" :state="errors.length > 0 ? false:null" name="phone" :options="options.phoneMask" :placeholder="$t('Labels.Phone')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="contact.email"
                :state="errors.length > 0 ? false:null"
                name="email"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Relationship')"
            label-for="relationship"
          >
            <validation-provider
              #default="{ errors }"
              name="relationship"
              rules="required"
            >
              <b-form-select
                name="relationship"
                :state="errors.length > 0 ? false:null"
                :options="relationshipOptions"
                v-model="contact.relationship"
                :reduce="v => v.value"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <h5 class="text-center mt-2">{{$t("Labels.Alerts")}}</h5>
      <hr>
      <b-row>
        <b-col>
          <b-card-text class="mb-0">
            {{$t("Labels.SMS")}}
          </b-card-text>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="contact.sms_notification"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-card-text class="mb-0">
            {{$t("Email")}}
          </b-card-text>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="contact.email_notification"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-card-text class="mb-0">
            {{$t("Labels.Call")}}
          </b-card-text>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="contact.call_notification"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-card-text class="mb-0">
            {{$t("Labels.Telegram")}}
          </b-card-text>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="contact.telegram_notification"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

      </b-row>
      <b-row>
        <b-col cols="12" md="6" offset-md="6" class="d-flex justify-content-end">
          <b-col cols="6">
            <b-button type="submit" variant="primary" block @click="validationForm" class="mt-3">{{$t("Labels.Save")}}</b-button>
          </b-col>
          &nbsp;
          <b-col cols="6">
            <b-button type="reset" block variant="danger" @click="cancel()" class="mt-3" >{{$t("Labels.Cancel")}}</b-button>
          </b-col>
        </b-col>
      </b-row>
      </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormCheckbox,
  BFormSelect
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

const DATA = {
  code: 200,
  data: {},
  msg: 'Ok'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSelect,
    Cleave
  },
  data () {
    return {
      contact: {
        relationship: null
      },
      required,
      DATA,
      uuid: this.$route.params.uuid,
      relationshipOptions: [
        { text: this.$t('Labels.SelectOption'), value: 0, disabled: true },
        { text: this.$t('Labels.Parents'), value: 1 },
        { text: this.$t('Labels.Childrens'), value: 2 },
        { text: this.$t('Labels.BroSis'), value: 3 },
        { text: this.$t('Labels.HusWife'), value: 4 },
        { text: this.$t('Labels.GrandParents'), value: 5 },
        { text: this.$t('Labels.Grandchildren'), value: 6 },
        { text: this.$t('Labels.Cousin'), value: 7 },
        { text: this.$t('Labels.Nephews'), value: 8 },
        { text: this.$t('Labels.Uncles'), value: 9 },
        { text: this.$t('Labels.SonInLaw'), value: 10 },
        { text: this.$t('Labels.ParentsInLaw'), value: 11 },
        { text: this.$t('Labels.BroInLaw'), value: 12 },
        { text: this.$t('Labels.NoParentship'), value: 13 },
        { text: this.$t('Labels.DistantFamily'), value: 14 },
        { text: this.$t('Labels.None'), value: 15 }
      ],
      options: {
        phoneMask: {
          blocks: [3, 3, 4],
          uppercase: true
        }
      }
    }
  },
  methods: {
    cancel () {
      this.contact = {}
      this.$refs.configValidation.reset()
      this.contact.email_notification = 0
      this.contact.call_notification = 0
      this.contact.telegram_notification = 0
      this.$router.push(`/contacts/${this.uuid}`)
    },
    async add () {
      const params = {
        url: 'tracker_contacts/create',
        method: 'POST',
        params: {
          name: this.contact.name,
          phone: this.contact.phone,
          email: this.contact.email,
          relationship: this.contact.relationship,
          enable_sms_notifications: this.contact.sms_notification ? '1' : '0',
          enable_email_notifications: this.contact.email_notification ? '1' : '0',
          enable_call_notifications: this.contact.call_notification ? '1' : '0',
          enable_telegram_notifications: this.contact.telegram_notification ? '1' : '0',
          tracker_uuid: this.uuid
        }
      }
      await request(params).then(response => {
        this.contact = {}
        this.$refs.configValidation.reset()
        this.$router.push(`/contacts/${this.uuid}`)
      })
    },
    validationForm () {
      this.$refs.configValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    }
  },
  created () {
  }
}
</script>
